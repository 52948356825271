<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { checkPermission, keyMaster, masterComputed, masterMethods, clientMethods } from '@/state/helpers';
import Swal from 'sweetalert2';
import { email, numeric, required } from 'vuelidate/lib/validators';
import LoadingIcon from '@/components/Loading/main.vue';
import { campaignStatus } from '@/config/var-common';
import Footer from '@/components/Footer/main.vue';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'エンドクライアント担当者詳細',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Layout,
        PageHeader,
        Footer
    },
    data() {
        return {
            title: 'エンドクライアント担当者詳細',
            index: 1,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'エンドクライアント一覧',
                    href: '/client/list'
                },
                {
                    text: 'エンドクライアント詳細',
                    active: true
                }
            ],
            client_id: this.$route.query.client_id,
            id: this.$route.query.id,
            form: {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                mobile_number: '',
                department: ''
            },
            clientData: {},
            listPartnerCP: [],
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            arrRoleClient: ['client.register', 'client.edit']
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required },
            email: { required, email },
            phone_number: { required, numeric },
            password: {
                required() {
                    // Kiểm tra queryId bắt buộc nếu tồn tại query id
                    if (!this.id && !this.form.password) {
                        return false;
                    }
                    return true; // Bỏ qua kiểm tra nếu không tồn tại query id
                }
            }
        }
    },
    mounted() {
        if (!this.checkPermiss(this.arrRoleClient[0]) && !this.checkPermiss(this.arrRoleClient[1])) {
            this.$router.push({ path: '/' }).then(() => {
                this.$bvToast.toast(`warning`, {
                    title: `アクセス権限がありません。`,
                    variant: 'warning',
                    toaster: 'b-toaster-top-center',
                    solid: true
                });
            });
        }
        if (this.$route.query.id && this.$route.query.client_id) {
            let data = {
                client_id: this.$route.query.client_id,
                id: this.$route.query.id
            };
            this.getDetail(data);
        }
        if (this.$route.query.client_id) {
            this.getDetailClient(this.$route.query.client_id);
        }
        this.getListMasterGroup();
    },
    methods: {
        ...masterMethods,
        ...clientMethods,
        getDetailClient(id) {
            this.loading = true;
            this.detailClient(id).then((data) => {
                this.clientData = data;
                this.loading = false;
            });
        },
        getDetail(id) {
            this.loading = true;
            this.detailClientSupport(id).then((data) => {
                this.form = data;
                this.listPartnerCP = data.campaigns;
                this.loading = false;
            });
        },
        getListMasterGroup() {
            this.listMaster(keyMaster['employee.group']).then((data) => {
                this.listDataMasterGroup = data;
            });
        },
        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            // stop here if form is invalid
            console.log(this.$v);
            this.$v.$touch();
            this.register();
        },
        renderSttText(numberStt) {
            const result = campaignStatus.find((item) => item.id === numberStt);
            return result ? result.text : '';
        },
        register() {
            if (this.form.name) {
                let data = Object.assign({}, this.form);
                data.partner_id = this.partner_id;
                this.registerClientSupport(data).then((data) => {
                    if (data.code == 200) {
                        this.$router.push({ path: '/partners/' });
                        this.$bvToast.toast(`Create Partners Success`, {
                            title: `Success!`,
                            variant: 'success',
                            solid: true
                        });
                    }
                });
            }
        },
        edit(item) {
            if (item.value) {
                let data = Object.assign(item);
                data.key = this.$route.query.key;
                this.updateMaster(data).then(() => {
                    this.$bvToast.toast(`Update Master Success`, {
                        title: `Success!`,
                        variant: 'success',
                        solid: true
                    });
                });
            }
        },
        goToEdit(path, id, client_id) {
            this.$router.push({
                path: path,
                query: { id: id, client_id: client_id }
            });
        },
        deleteObject(data) {
            Swal.fire({
                title: 'エンドクライアント担当者を削除してもよろしいですか。',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#5664d2',
                cancelButtonColor: '#b5b8bf',
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル'
            }).then((result) => {
                if (result.value) {
                    this.deleteClientSupport(data).then((res) => {
                        if (res.code == 200) {
                            this.$router
                                .push({
                                    path: `/client/form/view?id=${this.$route.query.client_id}`
                                })
                                .then(() => {
                                    this.$bvToast.toast(`success`, {
                                        title: `エンドクライアント担当者が削除されました。`,
                                        variant: 'success',
                                        toaster: 'b-toaster-top-center',
                                        solid: true
                                    });
                                });
                        }
                    });
                }
            });
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div v-if="!loading" class="row form__body--card form__common--center">
            <div class="col-lg-12">
                <div class="card form--sroll mb-0">
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">会社名</label>
                                        <div class="col-sm-9">{{ clientData.name }}</div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">部署</label>
                                        <div class="col-sm-9">
                                            {{ form.department }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">担当者</label>
                                        <div class="col-sm-9">
                                            {{ form.name }}
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">担当者(カナ)</label>
                                        <div class="col-sm-9">
                                            {{ form.kana_name }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.email') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.email }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.phone_number') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.phone_number }}
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-sm-3" for="validationCustom05">{{ $t('clientSupport.mobile_number') }}</label>
                                        <div class="col-sm-9">
                                            {{ form.mobile_number }}
                                        </div>
                                    </div>

                                    <hr />

                                    <h5><b>キャンペーン一覧</b></h5>

                                    <div class="table-responsive">
                                        <table class="table mb-0 table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>{{ $t('pagePartner.no') }}</th>
                                                    <th>CP名</th>
                                                    <th>ステータス</th>
                                                    <th>事務局開始日</th>
                                                    <th>CP終了日</th>
                                                    <th>事務局終了日</th>
                                                    <!--                                <th>担当</th>-->
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template>
                                                    <tr v-for="(item, k) in listPartnerCP" :key="'c' + item.id">
                                                        <td>{{ k + 1 }}</td>

                                                        <td>
                                                            {{ item.name }}<br />
                                                            {{ item.name_kana ? item.name_kana : '' }}
                                                        </td>
                                                        <td>
                                                            {{ renderSttText(item.progress_status) }}
                                                        </td>
                                                        <td>
                                                            {{ item.secretariat_start_date ? item.secretariat_start_date : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.campaign_end_date ? item.campaign_end_date : '' }}
                                                        </td>
                                                        <td>
                                                            {{ item.secretariat_end_date ? item.secretariat_end_date : '' }}
                                                        </td>
                                                        <!--                                    <td>-->
                                                        <!--                                        {{-->
                                                        <!--                                            item.person_in_charge-->
                                                        <!--                                                    ? item.person_in_charge.name-->
                                                        <!--                                                    : ""-->
                                                        <!--                                        }}-->
                                                        <!--                                    </td>-->

                                                        <td align="center">
                                                            <a
                                                                href="javascript:void(0)"
                                                                class="text-primary"
                                                                v-if="checkPermiss('partner.edit') || checkPermiss('partner.register')"
                                                                @click="goToEdit('/campaign/form/view', item.id)"
                                                            >
                                                                {{ $t('btn.detail') }}</a
                                                            >
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light mr-3"
                            @click="
                                $router.push({
                                    path: `/client/form/view?id=${$route.query.client_id}`
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>

                        <button
                            v-if="checkPermiss(arrRoleClient[0]) || checkPermiss(arrRoleClient[1])"
                            type="button"
                            class="btn btn-primary"
                            @click="goToEdit('/client/form/support', $route.query.id, $route.query.client_id)"
                        >
                            {{ $t('btn.edit') }}
                        </button>
                    </div>
                    <div v-if="$route.query.id">
                        <button
                            v-if="checkPermiss(arrRoleClient[0]) || checkPermiss(arrRoleClient[1])"
                            type="button"
                            class="btn btn-danger ml-3"
                            @click="
                                deleteObject({
                                    id: $route.query.id,
                                    client_id: $route.query.client_id
                                })
                            "
                        >
                            {{ $t('btn.delete') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
